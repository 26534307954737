import { Fragment } from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import DefaultHeader from "../components/DefaultHeader"
import Section from "../components/Section"
import Background from "../components/Background"
import { centerHorizontally } from "../styles/constants"
import { Heading } from "rebass/styled-components"
import Footer from "../components/Footer"
import * as React from "react"

const PrivacyPolicyPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <DefaultHeader />
    <div>
      <Section.Container id="privacyPolicy" Background={Background}>
        <Fragment>
          <div style={centerHorizontally}>
            <Heading
              as="h1"
              color="primary"
              fontSize={[4, 5]}
              mb={[3, 4, 5]}
              textAlign="center"
            >
              Privacy Policy
            </Heading>
            <div>
              Marten Schwarzmann built the ChallengeMe App app as a Free app.
              This SERVICE is provided by Marten Schwarzmann at no cost and is
              intended for use as is.
              <p>
                UX/UI design for the ChallengeMe App was created by{" "}
                <a
                  href="https://michaelehrnboeck.com/"
                  rel="noreferrer"
                  target="_blank"
                >
                  Michael Ehrnböck
                </a>
              </p>
            </div>
            <p>
              This page is used to inform visitors regarding my policies with
              the collection, use, and disclosure of Personal Information if
              anyone decided to use my Service.
            </p>
            <p>
              If you choose to use my Service, then you agree to the collection
              and use of information in relation to this policy. The Personal
              Information that I collect is used for providing and improving the
              Service. I will not use or share your information with anyone
              except as described in this Privacy Policy.
            </p>
            <p>
              The terms used in this Privacy Policy have the same meanings as in
              our Terms and Conditions, which is accessible at ChallengeMe App
              unless otherwise defined in this Privacy Policy.
            </p>
            <Heading
              as="h4"
              color="primary"
              fontSize={[2, 3]}
              mb={[1, 2, 3]}
              textAlign="center"
            >
              Information Collection and Use
            </Heading>
            <p>
              For a better experience, while using our Service, I may require
              you to provide us with certain personally identifiable
              information. The information is only used to provide the desired
              functionality and will not be given to any third party service nor
              being collected by me in any way.
            </p>
            <div>
              <p>
                The app does use third party services that may collect
                information used to identify you.
              </p>
              <p>
                Link to privacy policy of third party service providers used by
                the app
              </p>
              <ul>
                <li>
                  <a
                    href="https://www.google.com/policies/privacy/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Google Play Services
                  </a>
                </li>
              </ul>
            </div>
            <Heading
              as="h4"
              color="primary"
              fontSize={[2, 3]}
              mb={[1, 2, 3]}
              textAlign="center"
            >
              Log Data
            </Heading>
            <p>
              I want to inform you that whenever you use my Service, in a case
              of an error in the app I collect data and information (through
              third party products) on your phone called Log Data. This Log Data
              may include information such as your device Internet Protocol
              (“IP”) address, device name, operating system version, the
              configuration of the app when utilizing my Service, the time and
              date of your use of the Service, and other statistics.
            </p>
            <Heading
              as="h4"
              color="primary"
              fontSize={[2, 3]}
              mb={[1, 2, 3]}
              textAlign="center"
            >
              Cookies
            </Heading>
            <p>
              Cookies are files with a small amount of data that are commonly
              used as anonymous unique identifiers. These are sent to your
              browser from the websites that you visit and are stored on your
              device's internal memory.
            </p>
            <p>
              This Service does not use these “cookies” explicitly. However, the
              app may use third party code and libraries that use “cookies” to
              collect information and improve their services. You have the
              option to either accept or refuse these cookies and know when a
              cookie is being sent to your device. If you choose to refuse our
              cookies, you may not be able to use some portions of this Service.
            </p>
            <Heading
              as="h4"
              color="primary"
              fontSize={[2, 3]}
              mb={[1, 2, 3]}
              textAlign="center"
            >
              Service Providers
            </Heading>
            <p>
              I may employ third-party companies and individuals due to the
              following reasons:
            </p>
            <ul>
              <li>To facilitate our Service;</li>
              <li>To provide the Service on our behalf;</li>
              <li>To perform Service-related services; or</li>
              <li>To assist us in analyzing how our Service is used.</li>
            </ul>
            <p>
              I want to inform users of this Service that these third parties
              have access to your Personal Information. The reason is to perform
              the tasks assigned to them on our behalf. However, they are
              obligated not to disclose or use the information for any other
              purpose.
            </p>
            <Heading
              as="h4"
              color="primary"
              fontSize={[2, 3]}
              mb={[1, 2, 3]}
              textAlign="center"
            >
              Security
            </Heading>
            <p>
              I value your trust in providing us your Personal Information, thus
              we are striving to use commercially acceptable means of protecting
              it. But remember that no method of transmission over the internet,
              or method of electronic storage is 100% secure and reliable, and I
              cannot guarantee its absolute security.
            </p>
            <Heading
              as="h4"
              color="primary"
              fontSize={[2, 3]}
              mb={[1, 2, 3]}
              textAlign="center"
            >
              Links to Other Sites
            </Heading>
            <p>
              This Service may contain links to other sites. If you click on a
              third-party link, you will be directed to that site. Note that
              these external sites are not operated by me. Therefore, I strongly
              advise you to review the Privacy Policy of these websites. I have
              no control over and assume no responsibility for the content,
              privacy policies, or practices of any third-party sites or
              services.
            </p>
            <Heading
              as="h4"
              color="primary"
              fontSize={[2, 3]}
              mb={[1, 2, 3]}
              textAlign="center"
            >
              Children’s Privacy
            </Heading>
            <p>
              These Services do not address anyone under the age of 6. I do not
              knowingly collect personally identifiable information from
              children under 6. In the case I discover that a child under 6 has
              provided me with personal information, I immediately delete this
              from our servers. If you are a parent or guardian and you are
              aware that your child has provided us with personal information,
              please contact me so that I will be able to do necessary actions.
            </p>
            <Heading
              as="h4"
              color="primary"
              fontSize={[2, 3]}
              mb={[1, 2, 3]}
              textAlign="center"
            >
              Changes to This Privacy Policy
            </Heading>
            <p>
              I may update our Privacy Policy from time to time. Thus, you are
              advised to review this page periodically for any changes. I will
              notify you of any changes by posting the new Privacy Policy on
              this page.
            </p>
            <p>This policy is effective as of 2020-05-31</p>
            <Heading
              as="h4"
              color="primary"
              fontSize={[2, 3]}
              mb={[1, 2, 3]}
              textAlign="center"
            >
              Contact Us
            </Heading>
            <p>
              If you have any questions or suggestions about my Privacy Policy,
              do not hesitate to contact me at burritoapps@gmail.com.
            </p>
          </div>
        </Fragment>
      </Section.Container>
    </div>
    <Footer />
  </Layout>
)

export default PrivacyPolicyPage
